import { axiosDefault, axiosWAuth } from "./axios";
import { store } from "@/store/index";

class ApiRequest {
  constructor(args) {
    const { url = "/", method = "post", payload = null, isAuth = false } = args;
    this.url = url;
    this.method = method;
    this.payload = payload;
    this.isAuth = isAuth;
  }

  run = async () => {
    if (this.isAuth) {
      return axiosWAuth(store.getters.getConfig)[this.method](
        this.url,
        this.payload && this.payload
      );
    } else {
      return axiosDefault(store.getters.getConfig)[this.method](
        this.url,
        this.payload && this.payload
      );
    }
  };
}

export const login = async (user) => {
  const url = "/api/Users/authenticate";
  const request = new ApiRequest({ url, payload: user, isAuth: true });
  const response = await request.run();
  return response.data;
};

export const redirect = async (url) => {
  const request = new ApiRequest({ url });
  const response = await request.run();
  return response.data;
};

export const requestPasswordReset = async (user) => {
  const url = "/api/Users/requestpasswordreset";
  const request = new ApiRequest({ url, payload: user });
  const response = await request.run();
  return response.data;
};

export const resetPassword = async (info) => {
  const url = "/api/Users/resetpassword";
  const request = new ApiRequest({ url, payload: info });
  const response = await request.run();
  return response.data;
};

export const changePassword = async (user) => {
  const url = "/api/Users/changepassword";
  const request = new ApiRequest({ url, payload: user });
  const response = await request.run();
  return response.data;
};

//testing if client has a valid JWT cookie
export const checkauthcookie = async () => {
  const url = "/api/Users/checkauthcookie";
  const request = new ApiRequest({ url, method: "get", isAuth: true });
  const response = await request.run();
  return response.data;
};

//testing if client has a valid JWT in cookie or header
export const checkauth = async () => {
  const url = "/api/Users/checkauth";
  const request = new ApiRequest({ url, method: "get", isAuth: true });
  const response = await request.run();
  return response.data;
};

export const getInfoMessages = async () => {
  const url = "/api/Message/getinfomessages";
  const request = new ApiRequest({ url });
  const response = await request.run();
  return response.data;
};

export const getUserApplications = async (user) => {
  const formData = new FormData();
  for (const key in user) {
    formData.append(key, user[key]);
  }

  const url = "/api/Users/getuserapplications";
  const request = new ApiRequest({ url, payload: formData });
  const response = await request.run();
  return response.data;
};
