<template>
  <div class="main-auth">
    <!-- Header -->
    <b-row class="mb-4">
      <b-col>
        <div class="hero-container">
          <div class="pb-4 header-text-container">
            <div class="logo-login-row">
              <div class="logo-container">
                <img class="logo" src="@/images/mms_logo_white.svg" alt="" />
              </div>
            </div>
            <h1 class="heading text-left" v-html="heading"></h1>
            <h6 class="subheading text-right" v-html="subheading"></h6>
          </div>
          <div class="hero-image-container">
            <div class="p-5 bg-image">
              <b-container
                class="d-flex justify-content-center align-items-center h-100"
              >
                <div class="p-5 bg-image-overlay"></div>
              </b-container>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Auth",
  props: {
    heading: {
      type: String,
      default: "",
    },
    subheading: {
      type: String,
      default: "",
    },
    showLogin: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      username: "",
      password: "",
      errorMessage: "",
      showError: false,
      visibleBanner: false,
      returnUrl: "",
      showPassword: false,
    };
  },
  methods: {
    retrievePassword() {
      this.$router.push("/reset");
    },
  },
};
</script>

<style scoped>
.main-auth {
  background-color: var(--mms-site-dark-blue) !important;
  margin: 0;
  padding: 0;
  height: calc(100vh - 5rem);
}
.hero-container {
  position: relative;
}
.hero-image-container {
  width: 100%;
  height: 400px;
  position: relative;
  background-color: var(--mms-site-dark-blue);
  margin-bottom: 3.5rem;
}
.header-text-container {
  padding: 1.5rem 3rem 0 3rem;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-content: space-between;
}
.bg-image {
  position: absolute;
  background-size: cover;
  background-image: url("../images/istock-641292238.jpg");
  background-position: 50% 20%;
  background-repeat: no-repeat;
  clip-path: circle(55.7% at 75% 45%);
  height: 100%;
  height: 155vh;
  position: absolute !important;
  top: 90%;
  transform: translateY(-50%);
  right: 0;
  width: 100%;
  overflow: clip;
}
.bg-image::after {
  content: "";
  position: absolute;
  background-color: #12384f9d;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  clip-path: circle(55.7% at 75% 45%);
  height: 100%;
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 100%;
  overflow: clip;
}
.logo-container {
  position: relative;
  width: 9.2rem;
  top: 0;
  padding-top: 0.5rem;
}
@media only screen and (max-width: 850px) {
  .header-text-container {
    padding: 0.5rem 0.5rem 0 0.5rem;
  }
}

@media only screen and (max-height: 725px) {
  .main-auth {
    min-height: 650px;
  }
  .header-text-container {
    padding: 0.5rem 0.5rem 0 0.5rem;
  }
}
</style>
