<template>
  <div ref="clickTarget" tabindex="0" class="overlay">
    <div class="overlay-content">
    <div>
      <h6 v-if="obsMessage.length > 0" class="subheading">
        {{ obsMessage }}
      </h6>
    </div>
      <div class="login-form">
        <b-card
          title="Inloggning MMS verktyg"
          class="p-3 mb-2 mx-auto login-card"
        >
          <!-- show which webapp we are redirecting to? (based on returnUrl?) -->
          <b-card-text>
            <div class="text-field mt-5">
              <input
                name="nameuser"
                v-on:keyup.enter="login"
                type="text"
                required
                v-model="username"
                ref="inputUser"
              />
              <label>E-post eller användarnamn</label>
            </div>
            <div class="text-field mt-4">
              <input
                name="password"
                v-on:keyup.enter="login"
                :type="inputType"
                required
                v-model="password"
              />
              <label>Lösenord</label>
              <div class="mt-2">
                <b-form-checkbox v-model="showPassword" switch>
                  <small>Visa lösenord</small>
                </b-form-checkbox>
              </div>
            </div>
            <div class="d-flex flex-column">
              <b-link
                href="#"
                @click="retrievePassword"
                class="small text-primary"
                >Jag har glömt lösenordet</b-link
              >
            </div>
            <p v-if="showError" id="error" class="text-center text-danger mt-3">
              {{ errorMessage }}
            </p>
            <div>
              <div class="float-right mt-2">
                <b-button
                  class="btn btn-primary orange-button"
                  size="sm"
                  @click="login"
                  ref="loginButton"
                >
                  Logga in
                </b-button>
              </div>
            </div>
          </b-card-text>
        </b-card>
        <p class="contact-text">
          Kontakta support@mms.se vid frågor om inlogg/verktyg
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/api/api";
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      errorMessage: "",
      showError: false,
      visibleBanner: false,
      returnUrl: "",
      showPassword: false,
      obsMessage: "",
    };
  },
  computed: {
    inputType() {
      return this.showPassword ? "text" : "password";
    },
  },
  async mounted() {
    await this.getLatestObsMessage();
    const isAuthorized = await this.$store.dispatch(
      "checkAuthenticationAndGetUser"
    );
    if (isAuthorized) {
      this.$router.push("/portal");
      return;
    }
    // checking if autofill, to decide what element to focus on
    setTimeout(() => {
      let style = window.getComputedStyle(this.$refs.inputUser);
      if (style && style.backgroundColor === "rgba(0, 0, 0, 0)") {
        this.$refs.inputUser.focus();
      } else {
        this.$refs.loginButton.focus();
      }
    }, 250);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const newReturnUrl = urlParams.get("returnUrl");
    // Avoid updating the redirect url when just navigating within the login views
    // Add router path strings here to not redirect to them after logging in
    if (
      newReturnUrl &&
      !newReturnUrl.includes("password-reset-request") &&
      !newReturnUrl.includes("confirmation-message")
    ) {
      this.$store.commit("setRedirectUrl", newReturnUrl);
    }
  },
  methods: {
    updateUsername() {
      this.username = this.$refs.inputUser.value;
    },
    retrievePassword() {
      this.$router.push({
        name: "PasswordResetRequest",
        query: { username: this.username },
      });
    },
    handleError(error) {
      //Fel email och/eller lösenord
      let errorMessageSplit = error.split(" ");
      let statusCode = errorMessageSplit.at(-1);
      switch (parseInt(statusCode)) {
        case 500:
          this.errorMessage = "Inloggningen misslyckades p.g.a serverfel";
          break;
        case 400:
          this.errorMessage =
            "Inloggning misslyckades, kontrollera användarnamn och lösenord";
          break;
        case 401:
          this.errorMessage =
            "Inloggning misslyckades, kontrollera användarnamn och lösenord";
          break;
        default:
          this.errorMessage = "Inloggningen misslyckades p.g.a serverfel";
      }

      this.showError = true;
    },
    async login() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const returnUrl = urlParams.get("returnUrl");
      const userObj = {
        username: this.username,
        password: this.password,
        application: returnUrl,
      };
      try {
        this.showError = false;
        let response = await api.login(userObj);
        // Check if the browser has stored the cookie
        // to decide if using cookie or header for jwt
        await this.$store.dispatch("login", response);
        var useCookieJwt = await api.checkauthcookie();
        localStorage.setItem("useauthcookie", useCookieJwt);
        await this.$store.commit("setUsingJwtFromCookie", useCookieJwt);
        if (useCookieJwt) {
          await localStorage.removeItem("accesstoken");
          await this.$store.commit("setAccessToken", null);
        }
        if (response.token == null && !useCookieJwt) {
          throw { message: "no valid token" };
        }
        this.redirectToSite(response, returnUrl);
      } catch (err) {
        this.handleError(err.message);
        await this.$store.dispatch("logout");
      }
    },

    redirectToSite(response, returnUrl) {
      //Validate the return-url with user application, otherwise it could be abused to log on to any of our sites
      // use jwt from header
      if (!this.$store.getters.getUsingJwtFromCookie) {
        if (
          returnUrl == null ||
          response.isAuthorizedForApplication === false
        ) {
          this.$router.push({
            name: "Portal",
            query: { token: response.token },
          });
        } else {
          window.location.href = returnUrl + "?token=" + response.token;
        }
        // use jwt from cookie
      } else {
        if (
          returnUrl == null ||
          response.isAuthorizedForApplication === false
        ) {
          this.$router.push({ name: "Portal" });
        } else {
          window.location.href = returnUrl;
        }
      }
    },
    async getLatestObsMessage() {
      await api
        .getInfoMessages()
        .then((response) => {
          this.obsMessage = response.obsMessage;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
.login-form {
  font-family: "Lato", sans-serif !important;
}
.login-card {
  width: 26rem;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: -0.2px 1px 1.4px rgba(18, 56, 79, 0.09),
    -0.6px 3px 3.5px rgba(18, 56, 79, 0.067),
    -1.2px 6px 7.1px rgba(18, 56, 79, 0.061),
    -2.6px 12px 14.6px rgba(18, 56, 79, 0.054),
    -7px 24px 40px rgba(18, 56, 79, 0.04);
}

.btn-primary {
  white-space: nowrap;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 2.5rem;
  border-radius: 5px;
  letter-spacing: 0.5px;
  font-size: 1rem;
}

.text-field {
  position: relative;
  margin: 10px 2.5px 20px 2.5px;
  background-color: transparent !important;
}

input {
  display: inline-block;
  border: 1px solid rgb(201, 201, 201) !important;
  background-color: transparent !important;
  padding: 15px 14px;
  height: 50px;
  border-radius: 5px;
  width: 100%;
}

input:focus {
  border: 2px solid var(--primary) !important;
  background-color: transparent !important;
  outline: none;
}

label {
  margin-left: 7px;
  color: var(--tool-dark-gray);
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 13px;
  transition: 0.2s;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

input:-webkit-autofill ~ label,
input:autofill ~ label,
input:focus ~ label,
input:valid ~ label {
  margin-left: 0px;
  top: -9px;
  left: 10px;
  font-size: small;
  background-color: #fff;
  padding: 0 5px 0 5px;
}
input:valid ~ label {
  color: var(--tool-dark-gray);
}
input:focus ~ label {
  color: var(--primary);
}
.contact-text {
  text-align: center;
  font-size: 0.85rem;
  margin-top: 1.5rem;
  color: var(--mms-site-light-gray);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 650px;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.overlay-content {
  min-height: 425px;
}

.subheading {
  font-size: 1.1rem;
  color: rgb(255, 255, 255);
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-text-stroke: 0.2px black;
  padding: 1rem;
  /* margin-left: 10%;
  margin-right: 20%; */
  margin-bottom: 1rem;
  min-width: 100%;
  /* width: 150%; */
  max-width: 50vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}


@media only screen and (max-width: 500px) {
  .login-card {
    width: 100vw !important;
  }
}

@media only screen and (max-width: 800px) {
  .subheading {
    font-size: 1.1rem;
    color: rgb(255, 255, 255);
    text-align: center;
    background-color: rgba(0, 0, 0, 0.4);
    -webkit-text-stroke: 0.2px black;
    padding: 1rem;
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-height: 600px) {
  .overlay {
    min-height: 615px;
    position: absolute;
  }
}
</style>
