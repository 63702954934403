<template>
  <div ref="clickTarget" tabindex="0" class="overlay">
    <div class="overlay-content">
      <div class="login-form">
        <b-card
          title="Lösenordsåterställning"
          class="p-3 mb-2 mx-auto login-card"
        >
          <b-card-text>
            <div v-show="userName">
              <br>
              <p>Hej {{userName}}, här kan du skapa ditt lösenord</p>
            </div>
            <div class="d-flex justify-content-end mr-1 mt-4">
              <b-button
                size="sm"
                variant="primary"
                class="btn btn-generate custom-primary-button"
                @click="generatePassword"
                >Generera lösenord</b-button
              >
            </div>
            <div class="text-field mt-2">
              <input
                name="lösenord"
                v-on:keyup.enter="submitNewPassword"
                :type="inputType"
                required
                v-model="newPassword"
                @input="debouncedValidation"
                ref="newPasswordInput"
              />
              <label>Lösenord</label>
              <passwordMeter
                class="mt-1"
                :password="newPassword"
              ></passwordMeter>
            </div>
            <div class="text-field mt-4">
              <input
                name="repetera lösenord"
                v-on:keyup.enter="submitNewPassword"
                :type="inputType"
                required
                v-model="confirmPassword"
                @input="debouncedValidation"
              />
              <label>Repetera lösenord</label>
              <div class="mt-2">
                <b-form-checkbox v-model="showPassword" switch>
                  <small>Visa lösenord</small>
                </b-form-checkbox>
              </div>
            </div>
            <p v-if="showError" id="error" class="text-start text-danger mt-3">
              {{ errorMessage }}
            </p>
            <div>
              <div class="float-left mt-3">
                <b-button
                  class="btn btn-primary"
                  size="sm"
                  @click="$router.push('/')"
                >
                  Tillbaka
                </b-button>
              </div>
              <div class="float-right mt-3">
                <b-button
                  class="btn btn-primary orange-button"
                  size="sm"
                  @click="submitNewPassword"
                >
                  Spara lösenord
                </b-button>
              </div>
            </div>
          </b-card-text>
        </b-card>
        <p class="contact-text">
          Kontakta support@mms.se vid frågor om inlogg/verktyg
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/api/api";
import passwordMeter from "vue-simple-password-meter"

export default {
  name: "PasswordReset",
  components: {
    passwordMeter,
  },
  data() {
    return {
      newPassword: "",
      confirmPassword: "",
      showPassword: false,
      errorMessage: "",
      passwordStrength: 0,
      passwordColor: "red",
      encryptedUser: "",
      encryptedSecret: "",
      encryptedTimestamp: "",
      userName: "",
      showError: false,
      timeout: null,
    }
  },
  mounted() {
    // Extract the query parameters from the URL
    const query = this.$route.query
    this.encryptedUser = query.u
    this.encryptedSecret = query.s
    this.encryptedTimestamp = query.t
    this.userName = query.f

    // Focus the input or perform other necessary actions
    this.$refs.newPasswordInput.focus()
  },
  computed: {
    inputType() {
      return this.showPassword ? "text" : "password"
    },
    hasValidatedPasswords() {
      return this.newPassword && this.newPassword.length > 5 && this.newPassword === this.confirmPassword
    },
  },
  methods: {
    validatePassword() {
      const minLength = 6 // Custom threshold
      let length = this.newPassword.length
      if (length < 1) {
        this.errorMessage = ""
        this.showError = false
      } else if (length < minLength) {
        this.errorMessage =
          "Lösenordet måste vara minst " + minLength + " tecken långt."
        this.showError = true
      } else {
        this.showError = false
      }

      // Additional validation for password match
      if (this.newPassword !== this.confirmPassword && this.confirmPassword) {
        this.errorMessage = "Lösenorden matchar inte."
        this.showError = true
      }
    },
    generatePassword() {
      const letters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
      const numbers = "0123456789"
      const specialChars = "!@#$%&*()_+-=;:?"

      let generatedPassword = ""

      // Ensure at least one number and one special character
      generatedPassword += numbers.charAt(
        Math.floor(Math.random() * numbers.length)
      )
      generatedPassword += specialChars.charAt(
        Math.floor(Math.random() * specialChars.length)
      )

      // Fill the rest of the password with random characters
      for (let i = 2; i < 10; i++) {
        const allChars = letters + numbers
        generatedPassword += allChars.charAt(
          Math.floor(Math.random() * allChars.length)
        )
      }

      // Shuffle the password to mix up character types
      this.newPassword = generatedPassword
        .split("")
        .sort(() => 0.5 - Math.random())
        .join("")
      this.confirmPassword = this.newPassword
      this.validatePassword()
    },
    submitNewPassword() {
      // Passive validations
      this.validatePassword()
      if (!this.newPassword) {
        this.errorMessage = "Fyll i ett lösenord i översta fältet"
        this.showError = true
      } else if (!this.confirmPassword) {
        this.errorMessage = "Bekräfta ditt lösenord genom att fylla i det nedre fältet"
        this.showError = true
      } else if (this.newPassword !== this.confirmPassword) {
        this.errorMessage = "Lösenorden matchar inte."
        this.showError = true
      }
      if (!this.hasValidatedPasswords) return
      if (!this.showError) {
        const payload = {
          EncryptedUser: this.encryptedUser,
          EncryptedSecret: this.encryptedSecret,
          EncryptedTimestamp: this.encryptedTimestamp,
          NewPassword: this.newPassword,
        }
        api.resetPassword(payload)
          .then(() => {
            // this.$router.push("/");
            this.$router.push({
                name: "ConfirmationMessage",
                query: {
                  title: "Ditt lösenord uppdaterades",
                  message: `Var god logga in med ditt nya lösenord.`,
                  buttonName: "Till inloggning",
                  redirectRoute: "/",
                },
              });
          })
          .catch((error) => {
            console.error(error)
            if (error.response.data === "Link has expired.") {
              this.$router.push({
                name: "ConfirmationMessage",
                query: {
                  title: "Utgången länk",
                  message: `Den begärda länken för lösenordsåterställning är inte längre giltig. Vänligen begär en ny länk för att uppdatera ditt lösenord.`,
                  buttonName: "Till återställ lösenord",
                  redirectRoute: "password-reset-request",
                },
              });
              return
            } else if (error.response.data === "The new password cannot be the same as the current password.") {
              this.errorMessage = "Ditt nya lösenord måste skilja sig från ditt befintliga lösenord."
              this.showError = true
              return
            } 
            this.errorMessage =
              "Ett fel inträffade när lösenordet skulle sparas."
            this.showError = true
          })
      }
    },
    debouncedValidation() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.validatePassword, 500)
    },
  },
}
</script>

<style scoped>
.login-form {
  font-family: "Lato", sans-serif !important;
}
.login-card {
  width: 26rem;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: -0.2px 1px 1.4px rgba(18, 56, 79, 0.09),
    -0.6px 3px 3.5px rgba(18, 56, 79, 0.067),
    -1.2px 6px 7.1px rgba(18, 56, 79, 0.061),
    -2.6px 12px 14.6px rgba(18, 56, 79, 0.054),
    -7px 24px 40px rgba(18, 56, 79, 0.04);
}

.btn-primary {
  white-space: nowrap;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 2.5rem;
  border-radius: 5px;
  letter-spacing: 0.5px;
  font-size: 1rem;
}

.btn-generate {
}

.text-field {
  position: relative;
  margin: 10px 2.5px 20px 2.5px;
  background-color: transparent !important;
}

input {
  display: inline-block;
  border: 1px solid rgb(201, 201, 201) !important;
  background-color: transparent !important;
  padding: 15px 14px;
  height: 50px;
  border-radius: 5px;
  width: 100%;
}

input:focus {
  border: 2px solid var(--primary) !important;
  background-color: transparent !important;
  outline: none;
}

label {
  margin-left: 7px;
  color: var(--tool-dark-gray);
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 13px;
  transition: 0.2s;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

input:-webkit-autofill ~ label,
input:autofill ~ label,
input:focus ~ label,
input:valid ~ label {
  margin-left: 0px;
  top: -9px;
  left: 10px;
  font-size: small;
  background-color: #fff;
  padding: 0 5px 0 5px;
}
input:valid ~ label {
  color: var(--tool-dark-gray);
}
input:focus ~ label {
  color: var(--primary);
}
.contact-text {
  text-align: center;
  font-size: 0.85rem;
  margin-top: 1.5rem;
  color: var(--mms-site-light-gray);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 650px;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.overlay-content {
  min-height: 425px;
}

.password-strength-meter {
  width: 100%;
  height: 5px;
  background-color: #ddd;
  margin-top: 5px;
  border-radius: 3px;
}

.password-strength-meter div {
  width: 100%;
  height: 5px;
  background-color: green;
  border-radius: 3px;
}

.custom-primary-button {
  font-size: 0.85rem;
  height: 2rem;
}

@media only screen and (max-width: 500px) {
  .login-card {
    width: 100vw !important;
  }
}

@media only screen and (max-height: 600px) {
  .overlay {
    min-height: 615px;
    position: absolute;
  }
}
</style>
