import Vue from "vue"
import Vuex from "vuex"

import systemInfo from "./modules/systemInfo"
import auth from "./modules/auth"

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    systemInfo,
    auth,
  },
})
