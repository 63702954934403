<template>
  <div class="cookie-container">
    <b-modal
      id="modal-1"
      :visible="showModal"
      @hidden="hideModal"
      no-fade
      no-close-on-esc
      ok-only
      hide-footer
      @ok="acceptCookies"
      ok-title="Jag förstår"
      hide-header
    >
      <div
        class="d-flex align-items-center justify-content-center pt-3 m-2 mr-5"
      >
        <div class="cookie-text text-center">
          Denna webbplats och våra verktyg använder använder cookies för att
          säkerställa att du får den bästa upplevelsen.
          <a href="https://mms.se/cookies/" target="_blank"> Läs mer. </a>
        </div>
        <b-button @click="acceptCookies" variant="primary" class="ml-5"
          >Jag&nbsp;förstår</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
    }
  },
  mounted() {
    if (!this.getCookie("acceptCookies")) {
      this.showModal = true
    }
  },
  methods: {
    acceptCookies() {
      this.setCookie("acceptCookies", true, 365)
      this.showModal = false
    },
    hideModal() {
      this.showModal = false
    },
    setCookie(cname, cvalue, exdays) {
      var d = new Date()
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
      var expires = "expires=" + d.toUTCString()
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
    },
    getCookie(cname) {
      var name = cname + "="
      var decodedCookie = decodeURIComponent(document.cookie)
      var ca = decodedCookie.split(";")
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) === " ") {
          c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ""
    },
  },
}
</script>

<style scoped>
::v-deep .cookie-container {
  position: absolute !important;
  top: 0 !important;
}
::v-deep #modal-1 {
  position: fixed !important;
  top: 0 !important;
}
::v-deep .modal-dialog {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  margin: 0;
  z-index: 1030;
}
::v-deep .modal-content {
  border-radius: 0 !important;
  width: 100%;
  border: none;
  border-radius: 0;
}
::v-deep .modal-header {
  display: none;
}
::v-deep .modal-body {
  padding-top: 0;
}
</style>
