<template>
  <div class="overlay">
    <div class="overlay-content">
      <div class="login-form">
        <b-card title="Återställ lösenord" class="p-3 mb-2 mx-auto login-card">
          <p class="mt-4">
            Ange din e-postadress nedan och klicka på "Återställ lösenord". Du
            kommer att få ett e&#8209;postmeddelande med instruktioner för att
            återställa ditt lösenord. Tack!
          </p>
          <b-card-text>
            <div class="text-field mt-5 pb-2">
              <input
                v-on:keyup.enter="retrievePassword"
                required
                v-model="username"
                ref="inputUserEmail"
              />
              <label>E-postadress</label>
            </div>
            <p
              v-if="showErrorMessage"
              id="error"
              class="text-left small text-danger mt-3"
            >
              {{ errorMessage }}
            </p>
            <div class="mt-5">
              <div class="float-left mt-2">
                <b-button
                  class="btn btn-primary"
                  size="sm"
                  @click="$router.push('/')"
                >
                  &#11013; Logga in
                </b-button>
              </div>
              <div class="float-right mt-2">
                <b-button
                  class="btn btn-primary orange-button"
                  size="sm"
                  @click="retrievePassword"
                  ref="passwordResetButton"
                >
                  Återställ lösenord
                </b-button>
              </div>
            </div>
          </b-card-text>
        </b-card>
        <p class="contact-text">
          Kontakta support@mms.se vid frågor om inlogg/verktyg
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/api/api";
export default {
  data() {
    return {
      username: "", //email
      errorMessage: "",
      showErrorMessage: false,
      visibleBanner: false,
      showResetSuccessMessage: false,
    };
  },
  computed: {
    forcedRedirectUrl() {
      return this.$route.query.forcedRedirectUrl;
    },
  },
  mounted() {
    const usernameFromQuery = this.$route.query.username;
    if (usernameFromQuery) {
      this.username = usernameFromQuery;
      this.$refs.passwordResetButton.focus();
    } else {
      this.$refs.inputUserEmail.focus();
    }
  },
  methods: {
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    loginPageLink() {
      this.$router.push("/");
    },
    handleError(error) {
      let errorMessageSplit = error.split(" ");
      let statusCode = errorMessageSplit.at(-1);
      switch (parseInt(statusCode)) {
        case 500:
          this.errorMessage =
            "Anropet misslyckades p.g.a serverfel, kontakta support@mms.se för mer information.";
          break;
        default:
          this.errorMessage =
            "Anropet misslyckades, kontakta support@mms.se för mer information";
      }

      this.showErrorMessage = true;
    },
    async retrievePassword() {
      this.showErrorMessage = false;
      if (this.checkForm()) {
        const userObj = {
          username: this.username,
          password: null,
        };
        try {
          await api.requestPasswordReset(userObj).then((response) => {
            if (response === true) {
              this.$router.push({
                name: "ConfirmationMessage",
                query: {
                  title: "Lösenordsåterställning begärd",
                  message: `Om du har en giltig e-postadress registrerad kommer du inom 5-10 minuter att få en
                  länk skickad till dig för att återställa ditt lösenord. Var vänlig kontrollera även din skräppostmapp.`,
                  buttonName: "Till inloggning",
                  redirectRoute: "/",
                },
              });
              return;
            }
          });
        } catch (err) {
          this.handleError(err.message);
        }
      }
      this.showErrorMessage = true;
    },
    checkForm: function () {
      this.errorMessage = null;

      if (!this.username) {
        this.errorMessage = "Vänligen ange en e-postadress";
      } else if (!this.validEmail(this.username)) {
        this.errorMessage =
          "E-postadressen är ogiltig. Kontrollera och försök igen.";
      }
      return this.errorMessage == null;
    },
  },
};
</script>

<style scoped>
.login-form {
  font-family: "Lato", sans-serif !important;
}

.login-card {
  width: 26rem;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: -0.2px 1px 1.4px rgba(18, 56, 79, 0.09),
    -0.6px 3px 3.5px rgba(18, 56, 79, 0.067),
    -1.2px 6px 7.1px rgba(18, 56, 79, 0.061),
    -2.6px 12px 14.6px rgba(18, 56, 79, 0.054),
    -7px 24px 40px rgba(18, 56, 79, 0.04);
}

.btn-primary {
  white-space: nowrap;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 2.5rem;
  border-radius: 5px;
  letter-spacing: 0.5px;
  font-size: 1rem;
}

.text-field {
  position: relative;
  margin: 10px 2.5px 20px 2.5px;
  background-color: transparent !important;
}

input {
  display: inline-block;
  border: 1px solid rgb(201, 201, 201) !important;
  background-color: transparent !important;
  padding: 15px 14px;
  height: 50px;
  border-radius: 5px;
  width: 100%;
}

input:focus {
  border: 2px solid var(--primary) !important;
  background-color: transparent !important;
  outline: none;
}

label {
  margin-left: 7px;
  color: var(--tool-dark-gray);
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 13px;
  transition: 0.2s;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

input:focus ~ label,
input:valid ~ label {
  margin-left: 0px;
  top: -9px;
  left: 10px;
  font-size: small;
  background-color: #fff;
  padding: 0 5px 0 5px;
}

input:valid ~ label {
  color: var(--tool-dark-gray);
}
input:focus ~ label {
  color: var(--primary);
}
.contact-text {
  text-align: center;
  font-size: 0.85rem;
  margin-top: 1.5rem;
  color: var(--mms-site-light-gray);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 650px;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.overlay-content {
  min-height: 425px;
}

@media only screen and (max-width: 500px) {
  .login-card {
    width: 100vw;
  }
}

@media only screen and (max-height: 600px) {
  .overlay {
    min-height: 615px;
    position: absolute;
  }
}
</style>
