<template>
  <div id="app">
    <div class="d-flex flex-column min-vh-100">
      <div class="content-wrapper flex-grow-1">
        <router-view />
      </div>
      <div
        v-if="visibleBanner"
        class="alert alert-dark text-center mb-0 banner browserWarning"
        role="alert"
      >
        OBS: Verktyget är optimerat för Chrome browser

        <button
          type="button"
          class="btn btn-primary btn-sm ms-3 mb-lg-0 browserBannerBtn"
          @click="hideBrowserBanner()"
        >
          OK
        </button>
      </div>
      <Footer />
    </div>
  </div>
</template>
<script>
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Footer,
  },
  data() {
    return {
      visibleBanner: false,
    };
  },
  async mounted() {
    this.init();
    this.initializeBanner();
  },
  methods: {
    initializeBanner() {
      if (
        localStorage.getItem("browserBanner") == null &&
        !this.$browserDetect.isChrome
      )
        this.visibleBanner = true;
    },
    hideBrowserBanner() {
      localStorage.setItem("browserBanner", true);
      this.visibleBanner = false;
    },
    async checkVersion() {
      let variablesSetByClientVersion = await localStorage.getItem(
        "VariablesSetByClientVersion"
      );
      if (
        this.$store.getters.getConfig?.MiniumClientVersion &&
        (!variablesSetByClientVersion ||
          variablesSetByClientVersion <
            this.$store.getters.getConfig?.MiniumClientVersion)
      ) {
        await localStorage.clear();
      }
      await localStorage.setItem(
        "VariablesSetByClientVersion",
        this.$store.getters.getConfig?.CurrentClientVersion
      );
    },
    async init() {
      //send api request to check if we have a valid cookie
      const token = await localStorage.getItem("accesstoken");
      const user = await localStorage.getItem("user");
      await this.checkVersion();
      await localStorage.setItem("accesstoken", token);
      await localStorage.setItem("user", user);
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap");
#app {
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow: hidden;
}

.content-wrapper {
  /* Adjust the value based on the footer's height */
  min-height: calc(100% - 5rem);
}

.banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  border-radius: 0;
  background: #424851;
}
.browserWarning {
  color: red;
}
.browserBannerBtn {
  float: right;
  background: #97d058;
  border: none;
}
</style>
