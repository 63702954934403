<template>
  <div class="overlay">
    <div class="overlay-content">
      <div class="login-form">
        <b-card title="Byt lösenord" class="p-3 mb-2 mx-auto login-card">
          <b-card-text>
            <div class="text-field mt-5 pb-2">
              <input
                v-on:keyup.enter="changePassword"
                :type="inputType"
                required
                v-model="currentPassword"
                ref="inputUser"
                @input="onInput"
              />
              <label>Nuvarande lösenord</label>
            </div>
            <div class="text-field mt-5">
              <input
                v-on:keyup.enter="changePassword"
                :type="inputType"
                required
                v-model="newPassword"
                @input="onInput"
              />
              <label>Nytt lösenord</label>
            </div>
            <div class="text-field mt-4">
              <input
                v-on:keyup.enter="changePassword"
                :type="inputType"
                required
                v-model="confirmNewPassword"
                @input="onInput"
              />
              <label>Bekräfta nytt lösenord</label>

              <div class="mt-2">
                <b-form-checkbox v-model="showPassword" switch>
                  <small>Visa lösenord</small>
                </b-form-checkbox>
              </div>
            </div>

            <p
              v-if="showError"
              id="error"
              class="text-left small text-danger mt-3"
            >
              {{ displayMessage }}
            </p>

            <div class="mt-5">
              <div class="float-left mt-2">
                <b-button
                  class="btn btn-primary"
                  size="sm"
                  @click="$router.push('/portal')"
                >
                  Tillbaka
                </b-button>
              </div>
              <div class="float-right mt-2">
                <b-button
                  class="btn btn-primary orange-button"
                  size="sm"
                  @click="changePassword"
                >
                  Byt lösenord
                </b-button>
              </div>
            </div>
          </b-card-text>
        </b-card>
        <p class="contact-text">
          Kontakta support@mms.se vid frågor om inlogg/verktyg
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "@/api/api";
export default {
  name: "ChangePassword",
  props: {
    heading: {
      type: String,
      default: "",
    },
    subheading: {
      type: String,
      default: "",
    },
    showLogin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      username: "",
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      errorMessage: "",
      showError: false,
      visibleBanner: false,
      showPassword: false,
      validateMessage: "",
    };
  },
  computed: {
    inputType() {
      return this.showPassword ? "text" : "password";
    },
    displayMessage() {
      return this.validateMessage !== ""
        ? this.validateMessage
        : this.errorMessage;
    },
  },
  async mounted() {
    await this.$store.dispatch("checkAuthenticationAndGetUser");
    this.$refs.inputUser.focus();
  },
  methods: {
    handleError(error) {
      //Fel email och/eller lösenord
      let errorMessageSplit = error.split(" ");
      let statusCode = errorMessageSplit.at(-1);
      switch (parseInt(statusCode)) {
        case 500:
          this.errorMessage = "Inloggningen misslyckades p.g.a serverfel";
          break;
        case 400:
          this.errorMessage = "Kontrollera nuvarande lösenord";
          break;
        case 401:
          this.errorMessage = "Kontrollera nuvarande lösenord";
          break;
        case 403:
          this.errorMessage = "Kontrollera nuvarande lösenord";
          break;
        default:
          this.errorMessage = "Inloggningen misslyckades p.g.a serverfel";
      }

      this.showError = true;
    },
    validateForm() {
      if (
        !this.currentPassword ||
        !this.newPassword ||
        !this.confirmNewPassword
      ) {
        this.validateMessage = "Alla fält är obligatoriska";
      } else if (this.newPassword !== this.confirmNewPassword) {
        this.validateMessage =
          "Det nya lösenordet och bekräftelsen matchar inte";
      } else if (this.newPassword === this.currentPassword) {
        this.errorMessage =
          "Det nya lösenordet kan inte vara samma som det nuvarande lösenordet";
      } else if (
        !/(?=.*[0-9]|.*[!@#$%^&*])/.test(this.newPassword) ||
        !/.{8,}/.test(this.newPassword)
      ) {
        this.validateMessage =
          "Lösenordet måste innehålla minst 8 tecken och en siffra eller specialtecken";
      } else {
        this.validateMessage = "";
        this.showError = false;
        return;
      }

      this.showError = true;
    },
    async changePassword() {
      this.validateForm();
      const username =
        this.$store.getters.getUser !== null
          ? this.$store.getters.getUser
          : localStorage.getItem("user") ?? null;
      if (!username) {
        await this.$store.dispatch("logout");
        window.location.href =
          this.config_WebCaatAuthWebUrl +
          "?returnUrl=" +
          this.config_MyOwnApplicationUrl;
        this.$router.push("/");
      }
      if (!this.showError) {
        let request = {
          username: username,
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
        };
        //extra control, this view should be disabled if not logged in
        try {
          await api.changePassword(request);
          // pasword has been updated
          this.$router.push({
            name: "ConfirmationMessage",
            query: {
              title: "Lösenordsändring genomförd",
              message: `Ditt lösenord har nu uppdaterats. Använd det nya lösenordet vid
              nästa inloggning. Vi prioriterar alltid säkerheten för ditt konto.`,
              buttonName: "Till portalen",
              redirectRoute: "/portal",
            },
          });
        } catch (err) {
          this.handleError(err.message);
        }
      }
    },
    onInput() {
      this.showError = false;
    },
  },
};
</script>

<style scoped>
.login-form {
  font-family: "Lato", sans-serif !important;
}
.login-card {
  width: 26rem;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: -0.2px 1px 1.4px rgba(18, 56, 79, 0.09),
    -0.6px 3px 3.5px rgba(18, 56, 79, 0.067),
    -1.2px 6px 7.1px rgba(18, 56, 79, 0.061),
    -2.6px 12px 14.6px rgba(18, 56, 79, 0.054),
    -7px 24px 40px rgba(18, 56, 79, 0.04);
}

.btn-primary {
  white-space: nowrap;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 2.5rem;
  border-radius: 5px;
  letter-spacing: 0.5px;
  font-size: 1rem;
}

.btn-action {
  background-color: var(--mms-site-orange);
  border-color: var(--mms-site-orange);
  color: #fff;
}

.text-field {
  position: relative;
  margin: 10px 2.5px 20px 2.5px;
  background-color: transparent !important;
}

input {
  display: inline-block;
  border: 1px solid rgb(201, 201, 201) !important;
  background-color: transparent !important;
  padding: 15px 14px;
  height: 50px;
  border-radius: 5px;
  width: 100%;
}

input:focus {
  border: 2px solid var(--primary) !important;
  background-color: transparent !important;
  outline: none;
}

label {
  margin-left: 7px;
  color: var(--tool-dark-gray);
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 13px;
  transition: 0.2s;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}

input:focus ~ label,
input:valid ~ label {
  margin-left: 0px;
  top: -9px;
  left: 10px;
  font-size: small;
  background-color: #fff;
  padding: 0 5px 0 5px;
}

input:valid ~ label {
  color: var(--tool-dark-gray);
}
input:focus ~ label {
  color: var(--primary);
}
.contact-text {
  text-align: center;
  font-size: 0.85rem;
  margin-top: 1.5rem;
  color: var(--mms-site-light-gray);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 650px;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.overlay-content {
  min-height: 525px;
}

@media only screen and (max-width: 500px) {
  .login-card {
    width: 100vw;
  }
}

@media only screen and (max-height: 725px) {
  .overlay {
    min-height: 725px;
    position: absolute;
  }
}
</style>
