<template>
  <div class="overlay">
    <div class="overlay-content">
      <div class="login-form">
        <b-card :title="title" class="p-3 mb-2 mx-auto login-card">
          <b-card-text class="mt-5">
            <p>
              {{ message }}
            </p>

            <div class="mt-5">
              <div class="float-right mt-2">
                <b-button
                  class="btn btn-primary orange-button"
                  size="sm"
                  @click="routeDestination"
                  ref="routeButton"
                >
                  {{ buttonName }}
                </b-button>
              </div>
            </div>
          </b-card-text>
        </b-card>
        <p class="contact-text">
          Kontakta support@mms.se vid frågor om inlogg/verktyg
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmationMessage",
  data() {
    return {
      visibleBanner: false,
    };
  },
  computed: {
    title() {
      return this.$route.query.title;
    },
    message() {
      return this.$route.query.message;
    },
    buttonName() {
      return this.$route.query.buttonName;
    },
    redirectRoute() {
      return this.$route.query.redirectRoute;
    },
  },
  mounted() {
    this.$refs.routeButton.focus();
  },
  methods: {
    routeDestination() {
      this.$router.push(this.redirectRoute);
    },
  },
};
</script>

<style scoped>
.login-form {
  font-family: "Lato", sans-serif !important;
}
.login-card {
  width: 26rem;
  border: 1px solid white;
  border-radius: 10px;
  box-shadow: -0.2px 1px 1.4px rgba(18, 56, 79, 0.09),
    -0.6px 3px 3.5px rgba(18, 56, 79, 0.067),
    -1.2px 6px 7.1px rgba(18, 56, 79, 0.061),
    -2.6px 12px 14.6px rgba(18, 56, 79, 0.054),
    -7px 24px 40px rgba(18, 56, 79, 0.04);
}
.btn-primary {
  white-space: nowrap;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 2.5rem;
  border-radius: 5px;
  letter-spacing: 0.5px;
  font-size: 1rem;
}

.text-field {
  position: relative;
  margin: 10px 2.5px 20px 2.5px;
  background-color: transparent !important;
}

.contact-text {
  text-align: center;
  font-size: 0.85rem;
  margin-top: 1.5rem;
  color: var(--mms-site-light-gray);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 650px;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.overlay-content {
  min-height: 425px;
}

@media only screen and (max-width: 500px) {
  .login-card {
    width: 100vw;
  }
}

@media only screen and (max-height: 600px) {
  .overlay {
    min-height: 650px;
    position: absolute;
  }
}
</style>
