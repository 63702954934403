const state = {
  applicationHeader: "",
  clientVersion: "",
  apiVersion: "",
}
const getters = {
  getApplicationHeader: (state) => state.applicationHeader,
  getClientVersion: (state) => state.clientVersion,
  getApiVersion: (state) => state.apiVersion,
}
const mutations = {
  setApplicationHeader: (state, payload) => (state.applicationHeader = payload),
  setClientVersion: (state, payload) => (state.clientVersion = payload),
  setApiVersion: (state, payload) => (state.apiVersion = payload),
}

export default {
  state,
  mutations,
  getters,
}
