import Vue from "vue"
import VueRouter from "vue-router"
import Portal from "@/views/Portal"
import Auth from "@/views/Auth"
import PasswordResetRequest from "@/components/PasswordResetRequest"
import Login from "@/components/Login"
import ChangePassword from "@/components/ChangePassword"
import ConfirmationMessage from "@/components/ConfirmationMessage"
import PasswordReset from "@/components/PasswordReset"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    component: Auth,
    children: [
      {
        path: "", // default child path
        name: "Login",
        component: Login,
        props: true,
      },
      {
        path: "password-reset-request",
        name: "PasswordResetRequest",
        component: PasswordResetRequest,
      },
      {
        path: "change-password",
        name: "ChangePassword",
        component: ChangePassword,
      },
      {
        path: "/confirmation-message",
        name: "ConfirmationMessage",
        component: ConfirmationMessage,
        props: true,
      },
      {
        path: "/info",
        name: "PasswordReset",
        component: PasswordReset,
      },
    ],
  },
  {
    path: "/portal",
    name: "Portal",
    component: Portal,
  },
  {
    path: "*",
    redirect: "/",
  },
] // Routes Array

const router = new VueRouter({
  routes,
  mode: "history",
})

export default router
