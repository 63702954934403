var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-container"},[_c('div',{staticClass:"container-xxl"},[_c('div',[_c('div',{staticClass:"grid-primary"},[_c('div',{staticClass:"grid-item-primary"},[_c('div',{class:['card', { 'disabled-card': !this.access.tvAnalys }]},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"card-body"},[_c('a',{staticClass:"stretched-link",class:[
                    {
                      'disabled-link': !this.access.tvAnalys,
                    },
                    { 'custom-link-pointer': this.access.tvAnalys },
                  ],on:{"click":function($event){return _vm.onClickTargetUrl(
                      _vm.$store.getters.getConfig.LinkTvAnalys,
                      _vm.$store.getters.getConfig.TvAnalysId
                    )}}},[_vm._m(0)])]),_c('p',{staticClass:"ps-text text-muted"},[_vm._v("Data finns fr.o.m. 2019-01-01")])])])]),_c('div',{staticClass:"grid-item-primary",class:['card', { 'disabled-card': !this.access.onlineAnalys }]},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"card-body"},[_c('a',{staticClass:"stretched-link",class:[
                    { 'disabled-link': !this.access.onlineAnalys },
                    { 'custom-link-pointer': this.access.onlineAnalys },
                  ],on:{"click":function($event){return _vm.onClickTargetUrl(
                      _vm.$store.getters.getConfig.LinkOnlineAnalys,
                      _vm.$store.getters.getConfig.OnlineAnalysId
                    )}}},[_vm._m(1)])]),_c('p',{staticClass:"ps-text text-muted"},[_vm._v(" Data för program/klipp finns fr.o.m. 2021-05-10 och reklam fr.o.m. 2022-09-26 ")])])])]),_c('div',{staticClass:"grid-item-primary"},[_c('div',{staticClass:"card",class:['card', { 'disabled-card': !this.access.totalAnalys }]},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"card-body"},[_c('a',{staticClass:"stretched-link",class:[
                    { 'disabled-link': !this.access.totalAnalys },
                    { 'custom-link-pointer': this.access.totalAnalys },
                  ],on:{"click":function($event){return _vm.onClickTargetUrl(
                      _vm.$store.getters.getConfig.LinkTotalanalys,
                      _vm.$store.getters.getConfig.TotalAnalysId
                    )}}},[_vm._m(2)])]),_c('p',{staticClass:"ps-text text-muted"},[_vm._v(" Data för program och kanaler finns fr.o.m. 2023-01-01 och reklam fr.o.m. 2023-06-12 ")])])])]),_c('div',{staticClass:"grid-item-primary"},[_c('div',{staticClass:"card",class:[
              'card',
              { 'disabled-card': !this.access.filmCodeGenerator },
            ]},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"card-body"},[_c('a',{staticClass:"stretched-link",class:[
                    { 'disabled-link': !this.access.filmCodeGenerator },
                    { 'custom-link-pointer': this.access.filmCodeGenerator },
                  ],on:{"click":function($event){return _vm.onClickTargetUrl(
                      _vm.$store.getters.getConfig.LinkFilmcodegenerator,
                      _vm.$store.getters.getConfig.FilmCodeGeneratorId
                    )}}},[_vm._m(3)])])])])])]),_c('h2',{staticClass:"historic-data pt-5 pb-3 text-center"},[_vm._v("Historisk data")]),_c('div',{staticClass:"grid-secondary"},[_c('div',{staticClass:"grid-item-secondary"},[_c('div',{staticClass:"card",class:[
              'card',
              { 'disabled-card': !this.access.oldReklamanalys },
            ]},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"card-body"},[_c('a',{staticClass:"stretched-link",class:[
                    { 'disabled-link': !this.access.oldReklamanalys },
                    { 'custom-link-pointer': this.access.oldReklamanalys },
                  ],on:{"click":function($event){return _vm.onClickTargetUrl(
                      _vm.$store.getters.getConfig.LinkOldReklamanalysOnlinevideo,
                      _vm.$store.getters.getConfig.OldReklamanalysId
                    )}}},[_vm._m(4)])]),_c('p',{staticClass:"ps-text text-muted"},[_vm._v("Data finns t.o.m. 2022-09-25")])])])]),_c('div',{staticClass:"grid-item-secondary"},[_c('div',{staticClass:"card",class:['card', { 'disabled-card': !this.access.hotTopTv }]},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"card-body"},[_c('a',{staticClass:"stretched-link",class:[
                    { 'disabled-link': !this.access.hotTopTv },
                    { 'custom-link-pointer': this.access.hotTopTv },
                  ],on:{"click":function($event){return _vm.onClickTargetUrl(
                      _vm.$store.getters.getConfig.LinkOldTopplistaTV,
                      _vm.$store.getters.getConfig.HotTopTvId
                    )}}},[_vm._m(5)])]),_c('p',{staticClass:"ps-text text-muted"},[_vm._v("Data finns t.o.m. 2021-12-31")])])])]),_c('div',{staticClass:"grid-item-secondary"},[_c('div',{staticClass:"card",class:['card', { 'disabled-card': !this.access.programanalys }]},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"card-body"},[_c('a',{staticClass:"stretched-link",class:[
                    { 'disabled-link': !this.access.programanalys },
                    { 'custom-link-pointer': this.access.programanalys },
                  ],on:{"click":function($event){return _vm.onClickTargetUrl(
                      _vm.$store.getters.getConfig.LinkOldOnlineVideo,
                      _vm.$store.getters.getConfig.ProgramanalysId
                    )}}},[_vm._m(6)])]),_c('p',{staticClass:"ps-text text-muted"},[_vm._v("Data finns t.o.m. 2021-05-09")])])])]),_c('div',{staticClass:"grid-item-secondary"},[_c('div',{staticClass:"card",class:['card', { 'disabled-card': !this.access.hotTopWeb }]},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"card-body"},[_c('a',{staticClass:"stretched-link",class:[
                    { 'disabled-link': !this.access.hotTopWeb },
                    { 'custom-link-pointer': this.access.hotTopWeb },
                  ],on:{"click":function($event){return _vm.onClickTargetUrl(
                      _vm.$store.getters.getConfig.LinkToppslistaOnlinevideo,
                      _vm.$store.getters.getConfig.HotTopWebId
                    )}}},[_vm._m(7)])]),_c('p',{staticClass:"ps-text text-muted"},[_vm._v("Data finns t.o.m. 2023-10-02")])])])])]),_c('CookieModal')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media d-flex"},[_c('div',{staticClass:"pb-0"},[_c('img',{staticClass:"card-img-top primary-service-icon float-left",attrs:{"src":require("../images/service-icons/tv.svg"),"alt":""}})]),_c('div',{staticClass:"media-body text-left pl-3 align-self-center"},[_c('h6',[_vm._v("TV-Analys")]),_c('p',{staticClass:"pt-2"},[_vm._v(" Analysera tittarsiffror för program och reklam på linjär TV. Ta fram topplista för TV-program. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media d-flex"},[_c('div',{staticClass:"pb-0"},[_c('img',{staticClass:"card-img-top primary-service-icon float-left",attrs:{"src":require("../images/service-icons/online.svg"),"alt":""}})]),_c('div',{staticClass:"media-body text-left pl-3 align-self-center"},[_c('h6',[_vm._v("Online-Analys")]),_c('p',{staticClass:"pt-2"},[_vm._v(" Analysera tittarsiffror för program och reklam på onlinevideo. Ta fram topplista för program på onlinevideo. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media d-flex"},[_c('div',{staticClass:"pb-0"},[_c('img',{staticClass:"card-img-top primary-service-icon float-left",attrs:{"src":require("../images/service-icons/total.svg"),"alt":""}})]),_c('div',{staticClass:"media-body text-left pl-3 align-self-center"},[_c('h6',[_vm._v("Totalanalys")]),_c('p',{staticClass:"pt-2"},[_vm._v(" Analysera det totala tittandet ex räckvidd för TV + onlinevideo. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media d-flex"},[_c('div',{staticClass:"pb-0"},[_c('img',{staticClass:"card-img-top primary-service-icon float-left",attrs:{"src":require("../images/service-icons/filmcode.svg"),"alt":""}})]),_c('div',{staticClass:"media-body text-left pl-3 align-self-center"},[_c('h6',[_vm._v("Filmkodsgeneratorn")]),_c('p',{staticClass:"pt-2"},[_vm._v(" Generera svenska officiella filmkoder för TV+online. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media d-flex"},[_c('div',{staticClass:"pb-0"},[_c('img',{staticClass:"card-img-top primary-service-icon float-left",attrs:{"src":require("../images/service-icons/reklamanalys.svg"),"alt":""}})]),_c('div',{staticClass:"media-body text-left pl-3 align-self-center"},[_c('h6',[_vm._v("Reklamanalys Onlinevideo"),_c('br'),_vm._v("(Historiskt data)")]),_c('p',{staticClass:"pt-2"},[_vm._v(" Analysera reklamfilmer som visats online på uppmätta videotjänster. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media d-flex"},[_c('div',{staticClass:"pb-0"},[_c('img',{staticClass:"card-img-top primary-service-icon float-left",attrs:{"src":require("../images/service-icons/tvtop2.svg"),"alt":""}})]),_c('div',{staticClass:"media-body text-left pl-3 align-self-center"},[_c('h6',[_vm._v("Topplista TV"),_c('br'),_vm._v("(Historisk data)")]),_c('p',{staticClass:"pt-2"},[_vm._v(" Titta på historisk data för TV-topplistor. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media d-flex"},[_c('div',{staticClass:"pb-0"},[_c('img',{staticClass:"card-img-top primary-service-icon float-left",attrs:{"src":require("../images/service-icons/onlinevideo.svg"),"alt":""}})]),_c('div',{staticClass:"media-body text-left pl-3 align-self-center"},[_c('h6',[_vm._v("Onlinevideo"),_c('br'),_vm._v("(Historisk data)")]),_c('p',{staticClass:"pt-2"},[_vm._v(" Sök fram program och klipp som visats online på uppmätta videotjänster. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media d-flex"},[_c('div',{staticClass:"pb-0"},[_c('img',{staticClass:"card-img-top primary-service-icon float-left",attrs:{"src":require("../images/service-icons/toplista.svg"),"alt":""}})]),_c('div',{staticClass:"media-body text-left pl-3 align-self-center"},[_c('h6',[_vm._v("Topplista Onlinevideo"),_c('br'),_vm._v("(Historisk data)")]),_c('p',{staticClass:"pt-2"},[_vm._v(" Titta på topplistor på program och klipp som visats online på uppmätta videotjänster. ")])])])
}]

export { render, staticRenderFns }