<template>
  <footer>
    <div class="text-center p-2 footer-copyright">© {{ currentYear }} MMS</div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: "",
    }
  },
  mounted() {
    this.currentYear = this.getCurrentYear()
  },
  methods: {
    getCurrentYear() {
      var currentDate = new Date()
      var currentYear = currentDate.getFullYear()
      return currentYear.toString()
    },
  },
}
</script>

<style scoped>
footer {
  height: 5rem;
  background-color: var(--mms-site-dark-blue);
  color: var(--mms-site-light-gray);
  display: flex;
  justify-content: center;
  z-index: 100;
}
.footer-copyright {
  align-self: flex-end;
  margin-bottom: 0.5rem;
}
</style>
