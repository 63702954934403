<template>
  <b-row class="mb-4">
    <b-col>
      <div class="hero-container">
        <div class="pb-4 header-text-container">
          <div class="logo-login-row">
            <div class="logo-container">
              <img class="logo" src="@/images/mms_logo_white.svg" alt="" />
            </div>
            <b-dropdown
              id="dropdown-login"
              right
              split
              split-variant="outline-light"
              variant="light"
              :text="user"
              class="contextmenu-login"
              pointer-events="none"
            >
              <b-dropdown-item @click="changePassword"
                >Byt lösenord</b-dropdown-item
              >
              <b-dropdown-item @click="logout">Logga ut</b-dropdown-item>
            </b-dropdown>
          </div>
          <h1 class="heading text-left" v-html="heading"></h1>
          <b-row>
            <b-col cols="2"></b-col>
            <b-col cols="8"
              ><h6 v-if="infoMessage.length > 0" class="subheading">
                {{ infoMessage }}
              </h6></b-col
            >
            <b-col cols="2"></b-col>
          </b-row>
        </div>
        <div class="hero-image-container">
          <div class="p-5 bg-image">
            <b-container
              class="d-flex justify-content-center align-items-center h-100"
            >
              <div class="p-5 bg-image-overlay"></div>
            </b-container>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import * as api from "@/api/api";
export default {
  name: "Header",
  props: {
    heading: {
      type: String,
      default: "",
    },
    subheading: {
      type: String,
      default: "",
    },
    showLogin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: "",
      infoMessage: "",
    };
  },
  async mounted() {
    //check if we have a JWT in the header or in a cookie and
    //if none it returns 401 which triggers axios redirect
    await this.$store.dispatch("checkAuthenticationAndGetUser");
    this.user = this.$store.getters.getUser;
    this.getLatestInfoMessage();
  },
  methods: {
    async logout() {
      await this.$store.dispatch("logout");
      this.$router.push("/");
    },
    changePassword() {
      this.$router.push({ name: "ChangePassword" });
    },
    async getLatestInfoMessage() {
      await api
        .getInfoMessages()
        .then((response) => {
          this.infoMessage = response.dataLogMessage;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
.hero-container {
  position: relative;
}
.hero-image-container {
  width: 100%;
  height: 400px;
  position: relative;
  background-color: var(--mms-site-dark-blue);
  margin-bottom: 3.5rem;
}
.header-text-container {
  padding: 1.5rem 3rem 0 3rem;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-content: space-between;
}
.bg-image {
  position: absolute;
  background-size: cover;
  background-image: url("../images/istock-641292238.jpg");
  background-position: 50% 20%;
  background-repeat: no-repeat;
  clip-path: circle(55.7% at 75% 45%);
  height: 100%;
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 100%;
  overflow: clip;
}
.bg-image::after {
  content: "";
  position: absolute;
  background-color: #12384f9d;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  clip-path: circle(55.7% at 75% 45%);
  height: 100%;
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 100%;
  overflow: clip;
}
.logo-login-row {
  display: flex;
  justify-content: space-between;
}
.login-button {
  width: 7rem;
  padding: 7px;
  margin: 8px;
}
.contextmenu-login {
  background-color: #12384f6e;
  color: transparent;
  margin: 8px;
}
#dropdown-1 {
  max-height: 40px;
  align-self: center;
}
.logo-container {
  position: relative;
  width: 9.2rem;
  top: 0;
  padding-top: 0.5rem;
}
::v-deep .contextmenu-login > button:first-child:hover {
  border-color: #ffffff !important;
  background-color: transparent;
  color: var(--light);
  cursor: default;
}
.heading {
  position: absolute;
  font-size: 2.8rem;
  font-weight: bold;
  color: white;
  transform: translateY(-50%);
  top: 50%;
  padding-right: 3rem;
  margin: 0;
}
.subheading {
  font-size: 1.1rem;
  color: rgb(255, 255, 255);
  bottom: 0;
  margin-top: 260px;
  right: 3rem;

  padding: 1rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-text-stroke: 0.2px black; /* width and color */
}

@media only screen and (max-width: 799px) {
  .heading {
    font-size: 2rem;
  }
  .header-text-container {
    padding: 0.5rem 0.5rem 0 0.5rem;
  }

  .subheading {
    right: 0.5rem;
  }
}
</style>
