<template>
  <div class="main-container">
    <div class="container-xxl">
      <div>
        <div class="grid-primary">
          <div class="grid-item-primary">
            <div :class="['card', { 'disabled-card': !this.access.tvAnalys }]">
              <div class="card-content">
                <div class="card-body">
                  <a
                    @click="
                      onClickTargetUrl(
                        $store.getters.getConfig.LinkTvAnalys,
                        $store.getters.getConfig.TvAnalysId
                      )
                    "
                    class="stretched-link"
                    :class="[
                      {
                        'disabled-link': !this.access.tvAnalys,
                      },
                      { 'custom-link-pointer': this.access.tvAnalys },
                    ]"
                  >
                    <div class="media d-flex">
                      <div class="pb-0">
                        <img
                          src="../images/service-icons/tv.svg"
                          alt=""
                          class="card-img-top primary-service-icon float-left"
                        />
                      </div>
                      <div class="media-body text-left pl-3 align-self-center">
                        <h6>TV-Analys</h6>
                        <p class="pt-2">
                          Analysera tittarsiffror för program och reklam på
                          linjär TV. Ta fram topplista för TV-program.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <p class="ps-text text-muted">Data finns fr.o.m. 2019-01-01</p>
              </div>
            </div>
          </div>
          <div
            class="grid-item-primary"
            :class="['card', { 'disabled-card': !this.access.onlineAnalys }]"
          >
            <div class="card">
              <div class="card-content">
                <div class="card-body">
                  <a
                    @click="
                      onClickTargetUrl(
                        $store.getters.getConfig.LinkOnlineAnalys,
                        $store.getters.getConfig.OnlineAnalysId
                      )
                    "
                    class="stretched-link"
                    :class="[
                      { 'disabled-link': !this.access.onlineAnalys },
                      { 'custom-link-pointer': this.access.onlineAnalys },
                    ]"
                  >
                    <div class="media d-flex">
                      <div class="pb-0">
                        <img
                          src="../images/service-icons/online.svg"
                          alt=""
                          class="card-img-top primary-service-icon float-left"
                        />
                      </div>
                      <div class="media-body text-left pl-3 align-self-center">
                        <h6>Online-Analys</h6>
                        <p class="pt-2">
                          Analysera tittarsiffror för program och reklam på
                          onlinevideo. Ta fram topplista för program på
                          onlinevideo.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <p class="ps-text text-muted">
                  Data för program/klipp finns fr.o.m. 2021-05-10 och reklam
                  fr.o.m. 2022-09-26
                </p>
              </div>
            </div>
          </div>
          <div class="grid-item-primary">
            <div
              class="card"
              :class="['card', { 'disabled-card': !this.access.totalAnalys }]"
            >
              <div class="card-content">
                <div class="card-body">
                  <a
                    @click="
                      onClickTargetUrl(
                        $store.getters.getConfig.LinkTotalanalys,
                        $store.getters.getConfig.TotalAnalysId
                      )
                    "
                    class="stretched-link"
                    :class="[
                      { 'disabled-link': !this.access.totalAnalys },
                      { 'custom-link-pointer': this.access.totalAnalys },
                    ]"
                  >
                    <div class="media d-flex">
                      <div class="pb-0">
                        <img
                          src="../images/service-icons/total.svg"
                          alt=""
                          class="card-img-top primary-service-icon float-left"
                        />
                      </div>
                      <div class="media-body text-left pl-3 align-self-center">
                        <h6>Totalanalys</h6>
                        <p class="pt-2">
                          Analysera det totala tittandet ex räckvidd för TV +
                          onlinevideo.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>

                <p class="ps-text text-muted">
                  Data för program och kanaler finns fr.o.m. 2023-01-01 och
                  reklam fr.o.m. 2023-06-12
                </p>
              </div>
            </div>
          </div>
          <div class="grid-item-primary">
            <div
              class="card"
              :class="[
                'card',
                { 'disabled-card': !this.access.filmCodeGenerator },
              ]"
            >
              <div class="card-content">
                <div class="card-body">
                  <a
                    @click="
                      onClickTargetUrl(
                        $store.getters.getConfig.LinkFilmcodegenerator,
                        $store.getters.getConfig.FilmCodeGeneratorId
                      )
                    "
                    class="stretched-link"
                    :class="[
                      { 'disabled-link': !this.access.filmCodeGenerator },
                      { 'custom-link-pointer': this.access.filmCodeGenerator },
                    ]"
                  >
                    <div class="media d-flex">
                      <div class="pb-0">
                        <img
                          src="../images/service-icons/filmcode.svg"
                          alt=""
                          class="card-img-top primary-service-icon float-left"
                        />
                      </div>
                      <div class="media-body text-left pl-3 align-self-center">
                        <h6>Filmkodsgeneratorn</h6>
                        <p class="pt-2">
                          Generera svenska officiella filmkoder för TV+online.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 class="historic-data pt-5 pb-3 text-center">Historisk data</h2>
        <div class="grid-secondary">
          <div class="grid-item-secondary">
            <div
              class="card"
              :class="[
                'card',
                { 'disabled-card': !this.access.oldReklamanalys },
              ]"
            >
              <div class="card-content">
                <div class="card-body">
                  <a
                    @click="
                      onClickTargetUrl(
                        $store.getters.getConfig.LinkOldReklamanalysOnlinevideo,
                        $store.getters.getConfig.OldReklamanalysId
                      )
                    "
                    class="stretched-link"
                    :class="[
                      { 'disabled-link': !this.access.oldReklamanalys },
                      { 'custom-link-pointer': this.access.oldReklamanalys },
                    ]"
                  >
                    <div class="media d-flex">
                      <div class="pb-0">
                        <img
                          src="../images/service-icons/reklamanalys.svg"
                          alt=""
                          class="card-img-top primary-service-icon float-left"
                        />
                      </div>
                      <div class="media-body text-left pl-3 align-self-center">
                        <h6>Reklamanalys Onlinevideo<br />(Historiskt data)</h6>
                        <p class="pt-2">
                          Analysera reklamfilmer som visats online på uppmätta
                          videotjänster.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <p class="ps-text text-muted">Data finns t.o.m. 2022-09-25</p>
              </div>
            </div>
          </div>
          <div class="grid-item-secondary">
            <div
              class="card"
              :class="['card', { 'disabled-card': !this.access.hotTopTv }]"
            >
              <div class="card-content">
                <div class="card-body">
                  <a
                    @click="
                      onClickTargetUrl(
                        $store.getters.getConfig.LinkOldTopplistaTV,
                        $store.getters.getConfig.HotTopTvId
                      )
                    "
                    class="stretched-link"
                    :class="[
                      { 'disabled-link': !this.access.hotTopTv },
                      { 'custom-link-pointer': this.access.hotTopTv },
                    ]"
                  >
                    <div class="media d-flex">
                      <div class="pb-0">
                        <img
                          src="../images/service-icons/tvtop2.svg"
                          alt=""
                          class="card-img-top primary-service-icon float-left"
                        />
                      </div>
                      <div class="media-body text-left pl-3 align-self-center">
                        <h6>Topplista TV<br />(Historisk data)</h6>
                        <p class="pt-2">
                          Titta på historisk data för TV-topplistor.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <p class="ps-text text-muted">Data finns t.o.m. 2021-12-31</p>
              </div>
            </div>
          </div>
          <div class="grid-item-secondary">
            <div
              class="card"
              :class="['card', { 'disabled-card': !this.access.programanalys }]"
            >
              <div class="card-content">
                <div class="card-body">
                  <a
                    @click="
                      onClickTargetUrl(
                        $store.getters.getConfig.LinkOldOnlineVideo,
                        $store.getters.getConfig.ProgramanalysId
                      )
                    "
                    class="stretched-link"
                    :class="[
                      { 'disabled-link': !this.access.programanalys },
                      { 'custom-link-pointer': this.access.programanalys },
                    ]"
                  >
                    <div class="media d-flex">
                      <div class="pb-0">
                        <img
                          src="../images/service-icons/onlinevideo.svg"
                          alt=""
                          class="card-img-top primary-service-icon float-left"
                        />
                      </div>
                      <div class="media-body text-left pl-3 align-self-center">
                        <h6>Onlinevideo<br />(Historisk data)</h6>
                        <p class="pt-2">
                          Sök fram program och klipp som visats online på
                          uppmätta videotjänster.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <p class="ps-text text-muted">Data finns t.o.m. 2021-05-09</p>
              </div>
            </div>
          </div>
          <div class="grid-item-secondary">
            <div
              class="card"
              :class="['card', { 'disabled-card': !this.access.hotTopWeb }]"
            >
              <div class="card-content">
                <div class="card-body">
                  <a
                    @click="
                      onClickTargetUrl(
                        $store.getters.getConfig.LinkToppslistaOnlinevideo,
                        $store.getters.getConfig.HotTopWebId
                      )
                    "
                    class="stretched-link"
                    :class="[
                      { 'disabled-link': !this.access.hotTopWeb },
                      { 'custom-link-pointer': this.access.hotTopWeb },
                    ]"
                  >
                    <div class="media d-flex">
                      <div class="pb-0">
                        <img
                          src="../images/service-icons/toplista.svg"
                          alt=""
                          class="card-img-top primary-service-icon float-left"
                        />
                      </div>
                      <div class="media-body text-left pl-3 align-self-center">
                        <h6>Topplista Onlinevideo<br />(Historisk data)</h6>
                        <p class="pt-2">
                          Titta på topplistor på program och klipp som visats
                          online på uppmätta videotjänster.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <p class="ps-text text-muted">Data finns t.o.m. 2023-10-02</p>
              </div>
            </div>
          </div>
        </div>
        <CookieModal />
      </div>
    </div>
  </div>
</template>

<script>
import CookieModal from "@/components/CookieModal";
import * as api from "@/api/api";

export default {
  name: "Webservices",
  components: {
    CookieModal,
  },
  data() {
    return {
      userApplications: [], // Initialize as an empty array
      access: {
        tvAnalys: false,
        onlineAnalys: false,
        totalAnalys: false,
        filmCodeGenerator: false,
        oldReklamanalys: false,
        hotTopTv: false,
        programanalys: false,
        hotTopWeb: false,
      },
    };
  },
  created() {
    this.fetchUserApplications();
  },
  mounted() {},
  methods: {
    onClickTargetUrl(webserviceUrl, applicationId) {
      if (!this.userApplications.includes(applicationId)) return;

      let linkUrl = this.omitHeaderJwt
        ? webserviceUrl
        : webserviceUrl + "?token=" + this.jwt;
      window.location.href = linkUrl;
    },
    AccessControl() {
      if (!this.userApplications || this.userApplications.length === 0) {
        return; // Exit if userApplications is not set or empty
      }

      const getApps = (id) => this.userApplications.includes(id);

      this.access.tvAnalys = getApps(this.$store.getters.getConfig.TvAnalysId);
      this.access.onlineAnalys = getApps(
        this.$store.getters.getConfig.OnlineAnalysId
      );
      this.access.totalAnalys = getApps(
        this.$store.getters.getConfig.TotalAnalysId
      );
      this.access.filmCodeGenerator = getApps(
        this.$store.getters.getConfig.FilmCodeGeneratorId
      );
      this.access.oldReklamanalys = getApps(
        this.$store.getters.getConfig.OldReklamanalysId
      );
      this.access.hotTopTv = getApps(this.$store.getters.getConfig.HotTopTvId);
      this.access.programanalys = getApps(
        this.$store.getters.getConfig.ProgramanalysId
      );
      this.access.hotTopWeb = getApps(
        this.$store.getters.getConfig.HotTopWebId
      );
    },
    async fetchUserApplications() {
      let userName = await this.fetchUser();
      const userObj = {
        username: userName,
        password: "",
        application: "",
      };
      await api
        .getUserApplications(userObj)
        .then((response) => {
          let applicationsIds = response.map(
            (application) => application.baseApplicationId
          );
          this.userApplications = applicationsIds;
          this.AccessControl();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async fetchUser() {
      const username =
        this.$store.getters.getUser !== null
          ? this.$store.getters.getUser
          : localStorage.getItem("user") ?? null;
      if (!username) {
        await this.$store.dispatch("logout");
        window.location.href =
          this.config_WebCaatAuthWebUrl +
          "?returnUrl=" +
          this.config_MyOwnApplicationUrl;
        this.$router.push("/");
      }
      return username;
    },
  },

  computed: {
    omitHeaderJwt() {
      return this.$store.getters.getUsingJwtFromCookie !== null
        ? this.$store.getters.getUsingJwtFromCookie
        : localStorage.getItem("useauthcookie") ?? null;
    },
    jwt() {
      return this.$store.getters.getAccessToken !== null
        ? this.$store.getters.getAccessToken
        : localStorage.getItem("accesstoken") ?? null;
    },
  },
};
</script>

<style scoped>
.container-xxl {
  background-color: var(--mms-site-light-gray);
}

.hero-container {
  position: relative;
}
.hero-image-container {
  width: 100%;
  height: 400px;
  position: relative;
  background-color: var(--mms-site-dark-blue);
  margin-bottom: 5rem;
}
.header-text-container {
  padding: 1.5rem 3rem 0 3rem;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-content: space-between;
}
.bg-image {
  position: absolute;
  background-size: cover;
  background-image: url("../images/istock-641292238.jpg");
  background-position: 50% 20%;
  background-repeat: no-repeat;
  clip-path: circle(55.7% at 75% 45%);
  height: 100%;
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 100%;
  overflow: clip;
}
.bg-image::after {
  content: "";
  position: absolute;
  background-color: #12384f9d;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  clip-path: circle(55.7% at 75% 45%);
  height: 100%;
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 100%;
  overflow: clip;
}
.container-xxl {
  margin: auto;
  max-width: 100rem;
  padding: 0 3rem;
  padding-bottom: 12rem;
}
.logo-container {
  position: absolute;
  width: 9.2rem;
  top: 0;
  padding-top: 2rem;
}
.heading {
  position: absolute;
  font-size: 2.8rem;
  font-weight: bold;
  color: white;
  transform: translateY(-50%);
  top: 50%;
  padding-right: 3rem;
  margin: 0;
}
.subheading {
  position: absolute;
  font-size: 1rem;
  color: var(--mms-site-light-gray);
  bottom: 0;
  right: 3rem;
  padding-left: 3rem;
  padding-bottom: 1rem;
}
.grid-primary {
  margin: 0;
  margin-bottom: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-areas: "p1" "p2" "p3" "p4";
  grid-template-columns: 1fr;
}
.grid-secondary {
  margin: 0;
  margin-bottom: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-areas: "s1" "s2" "s3" "s4";
  grid-template-columns: 1fr;
}
.grid-item-primary:nth-child(1) {
  grid-area: p1;
}
.grid-item-primary:nth-child(2) {
  grid-area: p2;
}
.grid-item-primary:nth-child(3) {
  grid-area: p3;
}
.grid-item-primary:nth-child(4) {
  grid-area: p4;
}

.grid-item-secondary:nth-child(1) {
  grid-area: s1;
}
.grid-item-secondary:nth-child(2) {
  grid-area: s2;
}
.grid-item-secondary:nth-child(3) {
  grid-area: s3;
}
.grid-item-secondary:nth-child(4) {
  grid-area: s4;
}
.card {
  flex-direction: row;
  margin-bottom: 0rem;
  height: 12rem;
  border: 1px solid transparent;
  border-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  transition-property: box-shadow, border-color;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;
  transition-delay: 25ms;
  background-color: white;
}
.card:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.09) 0px 2px 4px -1px;
  border-color: var(--mms-site-mid-blue);
  transition-duration: 150ms;
}
.card:active {
  background-color: #d3edfc;
}
.card-content {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}
.card-body {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}
.card-body a {
  text-decoration: none;
}
.primary-service-icon {
  width: 4rem;
  margin: 0rem 0rem;
  margin-right: 0.5rem;
}
.media-body {
  padding: 0.5rem;
}
.media-body h6 {
  font-size: 1.2rem;
  letter-spacing: -0.5px;
  font-weight: 700;
  color: var(--mms-site-dark-blue);
}
.media-body p {
  height: 4rem;
  font-size: 0.8rem;
  color: var(--mms-site-dark-blue);
}
.ps-text {
  margin-top: 5px;
  font-size: 0.6rem;
  padding-left: 6.7rem;
  padding-right: 1.5rem;
  padding-bottom: 0rem;
}
.historic-data {
  color: var(--mms-site-dark-blue);
}
.background-dev {
  color: #fff;
  background-color: #736c94;
}
.background-test {
  color: #fff;
  background-color: #820d0d;
}
.custom-link-pointer:hover {
  cursor: pointer;
}

.disabled-card {
  pointer-events: none !important;
  opacity: 0.4;
}

.disabled-link {
  pointer-events: none !important;
  cursor: not-allowed;
  color: gray;
}

@media only screen and (max-width: 799px) {
  .heading {
    font-size: 2rem;
  }
  .header-text-container {
    padding: 0.5rem 0.5rem 0 0.5rem;
  }
  .logo-container {
    padding-top: 1rem;
  }
  .subheading {
    right: 0.5rem;
  }
  .container-xxl {
    padding: 0 0.5rem;
    padding-bottom: 12rem;
  }
  .grid-secondary {
    grid-template-areas: "s1" "s2" "s3" "s4";
    grid-template-columns: 1fr;
  }
  .grid-primary {
    grid-template-areas: "p1" "p2" "p3" "p4";
    grid-template-columns: 1fr;
  }
}
@media only screen and (min-width: 800px) {
  .grid-primary {
    grid-template-areas: "p1 p1 p2 p2" "p3 p3 p4 p4";
    grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
  }
  .grid-secondary {
    grid-template-areas: "s1 s1 s2 s2" "s3 s3 s4 s4";
    grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
  }
}
@media only screen and (min-width: 1500px) {
  .grid-primary {
    grid-template-areas: "p1 p2 p3 p4";
    grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
  }
  .grid-secondary {
    grid-template-areas: "s1 s2 s3 s4";
    grid-template-columns: 0.25fr 0.25fr 0.25fr 0.25fr;
  }
}
</style>
