import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import { store } from "./store"
import { BootstrapVue, IconsPlugin, ToastPlugin } from "bootstrap-vue"
import browserDetect from "vue-browser-detect-plugin"

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(ToastPlugin)
Vue.use(browserDetect)

import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import "@/css/global.css"

Vue.config.productionTip = false

store.dispatch("loadConfig").then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app")
})
