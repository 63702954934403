import axios from "axios"
import { store } from "@/store/index"
import router from "@/router/index"

export function axiosDefault() {
  const instance = axios.create({
    baseURL: store.getters.getConfig?.WebApiUrl || "https://www.mmsdata.se/",
  })
  return instance
}

export function axiosWAuth() {
  const instance = axios.create({
    baseURL: store.getters.getConfig?.WebApiUrl || "https://www.mmsdata.se/",
    withCredentials: true,
  })
  //Use this as fallback
  //Add Authorization to request header
  const useAuthCookie =
    store.getters.getUsingJwtFromCookie !== null
      ? store.getters.getUsingJwtFromCookie
      : localStorage.getItem("useauthcookie") ?? null
  if (!useAuthCookie) {
    const jwt =
      store.getters.getAccessToken !== null
        ? store.getters.getAccessToken
        : localStorage.getItem("accesstoken") ?? null
    instance.interceptors.request.use(
      (config) => {
        config.headers = {
          ...config.headers,
          Authorization: "Bearer " + jwt,
        }
        return config
      },
      (error) => Promise.reject(error)
    )
  }

  //Check responses for 401 response (reach out to refresh token here when we get refresh token)
  instance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response.status === 401) {
        return new Promise((resolve, reject) => {
          store.dispatch("logout")
          if (router.history.current.path !== "/") router.push("/")
          reject(error)
        })
      } else {
        return new Promise((resolve, reject) => reject(error))
      }
    }
  )

  return instance
}
