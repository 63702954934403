import Cookies from "js-cookie";
import axios from "axios";
import * as api from "@/api/api";

const state = {
  config: null,
  loggedIn: false,
  user: null,
  accessToken: null,
  redirectUrl: null,
  usingJwtFromCookie: false,
  userApplications: [],
};

const getters = {
  getConfig: (state) => state.config,
  getLoggedIn: (state) => state.loggedIn,
  getUser: (state) => state.user,
  getAccessToken: (state) => state.accessToken,
  getRedirectUrl: (state) => state.redirectUrl,
  getUsingJwtFromCookie: (state) => state.usingJwtFromCookie,
  getUserApplications: (state) => state.userApplications,
};

const mutations = {
  setConfig: (state, payload) => (state.config = payload),
  setLoggedIn: (state, payload) => (state.loggedIn = payload),
  setUser: (state, payload) => (state.user = payload),
  setAccessToken: (state, payload) => (state.accessToken = payload),
  setRedirectUrl: (state, payload) => (state.redirectUrl = payload),
  setUsingJwtFromCookie: (state, payload) =>
    (state.usingJwtFromCookie = payload),
  setUserApplications: (state, payload) => (state.userApplications = payload),
};

const actions = {
  async loadConfig({ commit }) {
    const config = await axios
      .get("./config/runtime.json")
      .then((conf) => conf.data);
    await commit("setConfig", config);
  },
  async login({ commit }, payload) {
    // Include state in the context here
    const token = payload.token;
    await localStorage.setItem("accesstoken", token);
    await commit("setAccessToken", token);
    const username = payload.authenticationStatus.userName;
    await localStorage.setItem("user", username);
    await commit("setUser", username);
    await commit("setLoggedIn", true);

    let applicationsIds = payload.authenticationStatus.userApplications.map(
      (application) => application.applicationId
    );

    await commit("setUserApplications", applicationsIds);
  },
  async logout({ commit, state }) {
    // Include state in the context here
    await localStorage.removeItem("accesstoken");
    await localStorage.removeItem("user");
    //remove cookie
    await Cookies.remove("WebservicesJwt", {
      domain: state.config.AuthCookieDomain,
      path: "/",
      secure: true,
    });
    await commit("setUser", null);
    await commit("setAccessToken", null);
    await commit("setLoggedIn", false);
  },
  async checkAuthenticationAndGetUser({ commit, dispatch }) {
    let response = await api.checkauth();
    if (!response) {
      return false;
    }

    localStorage.setItem("user", response);
    await dispatch("loadConfig");
    await commit("setUser", response);

    return true;
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
