<template>
  <div class="portal">
    <Header
      heading="Välkommen till portalen för MMS&nbsp;analysverktyg"
      subheading="Denna sida fungerar som en portal
            för&nbsp;att&nbsp;nå&nbsp;MMS&nbsp;olika&nbsp;webbverktyg."
    />
    <Webservices />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Webservices from "@/components/Webservices.vue";

export default {
  name: "Portal",
  components: {
    Header,
    Webservices,
  },
};
</script>
